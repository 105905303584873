import { useTranslations } from "next-intl";
import React from "react";
import {
  isLoginModalOpenedVar,
  isProModalOpenedVar,
} from "../../graphql/cache";
import { useDisconnectUserMutation, User } from "../../resolvers-types";
import Dropdown from "../dropdown/Dropdown";
import DropdownItem from "../dropdown/DropdownItem";
import Image from "next/image";
import { handleSignOut } from "../../utils/common";
import isEmail from "validator/lib/isEmail";
import CurrentUserType from "../currentUserType/CurrentUserType";

interface SignedInUserDropdownProps {
  currentUser?: User;
}

export default function SignedInUserDropdown({
  currentUser,
}: SignedInUserDropdownProps) {
  const translate = useTranslations();
  const [disconnectUser] = useDisconnectUserMutation();

  const openPremiumModal = () => {
    isProModalOpenedVar(true);
  };

  const showLoginModal = () => {
    isLoginModalOpenedVar(true);
  };

  const logoutUser = async () => {
    await disconnectUser();
    await handleSignOut();
  };

  const subProps = currentUser?.isPremium
    ? { url: "/me/subscription" }
    : { onClick: openPremiumModal };

  const isGuest = !isEmail(currentUser?.email || "");

  const additionalItems = isGuest
    ? [
        <DropdownItem
          title={translate("login") as string}
          key={"dropdown-login-guest"}
          onClick={showLoginModal}
        />,
      ]
    : [];

  return (
    <div className="flex flex-row items-center h-full">
      <Dropdown
        menuItems={[
          ...additionalItems,
          <DropdownItem
            title={translate("profile") as string}
            url="/me/profile"
            key={"dropdown-profile"}
          />,
          <DropdownItem
            title={translate("subscription") as string}
            key={"dropdown-sub"}
            {...subProps}
          />,
          <DropdownItem
            title={translate("logout") as string}
            onClick={logoutUser}
            key={"dropdown-logout"}
          />,
        ]}
      >
        <div className="flex flex-row items-center">
          <CurrentUserType currentUser={currentUser} />
          <div className="flex flex-row items-center">
            <Image
              src={currentUser?.avatarUrl}
              alt="me"
              width="28"
              height="28"
              className="rounded-lg"
            />
            <div className="font-semibold cursor-pointer text-sm ml-2">
              {currentUser?.displayName}
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
