import { faGem, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import { User } from "../../resolvers-types";
import isEmail from "validator/lib/isEmail";
import { isLoginModalOpenedVar } from "../../graphql/cache";

interface CurrentUserTypeProps {
  currentUser: User;
}

export default function CurrentUserType({ currentUser }: CurrentUserTypeProps) {
  const translate = useTranslations();
  const isPremium = currentUser?.isPremium;
  const isGuest = !isEmail(currentUser?.email || "");

  const openLoginModal = () => {
    isLoginModalOpenedVar(true);
  };

  if (isGuest) {
    return (
      <div
        className="flex flex-row items-center justify-center mr-6 px-2 py-1 rounded-lg bg-teal-500 cursor-pointer"
        onClick={openLoginModal}
      >
        <FontAwesomeIcon
          icon={faUser}
          className="text-white mr-2 cursor-pointer"
          size="xs"
        />
        <div className="text-white font-bold text-xs">{translate("guest")}</div>
      </div>
    );
  }

  if (isPremium) {
    return (
      <Link href={"/me/subscription"}>
        <a className="hover:no-underline no-underline flex flex-row items-center justify-center mr-6 px-2 py-1 rounded-lg bg-amber-500">
          <FontAwesomeIcon
            icon={faGem}
            className="text-white mr-2 cursor-pointer"
            size="xs"
          />
          <div className="text-white font-bold text-xs">
            {translate("proUser")}
          </div>
        </a>
      </Link>
    );
  }
  return null;
}
