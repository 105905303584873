import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React from "react";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useGetJoinedRoomsQuery } from "../../resolvers-types";
import Dropdown from "../dropdown/Dropdown";
import DropdownItem from "../dropdown/DropdownItem";
import MenuItemWithDropdown from "./MenuItemWithDropdown";

const SHOWED_ROOMS_AMOUNT = 5;

export default function JoinedRoomsDropdown() {
  const translate = useTranslations();
  const router = useRouter();
  const isInEstimateRoom = router.pathname.includes("estimate");
  const { currentUser } = useCurrentUser();
  const { data: dataJoinedRooms } = useGetJoinedRoomsQuery({
    skip: !currentUser,
  });

  const handleClickRoom = (uniqId: string) => () => {
    window.location.href = `/estimate/${uniqId}`;
  };

  const joinedRooms = dataJoinedRooms?.joinedRooms || [];
  const joinedRoomItems = joinedRooms
    .slice(0, SHOWED_ROOMS_AMOUNT)
    .map((room) => {
      const { id, name, uniqId } = room;
      const additionalProps = isInEstimateRoom
        ? { onClick: handleClickRoom(uniqId) }
        : { url: `/estimate/${uniqId}` };
      return (
        <DropdownItem
          key={`dropdown-joined-estimate-room-${id}`}
          title={name}
          {...additionalProps}
        />
      );
    });

  return (
    <Dropdown
      menuItems={[
        ...joinedRoomItems,
        <DropdownItem
          title={translate("viewAllRooms") as string}
          url="/estimate/joined-rooms"
          key={"dropdown-view-all-rooms"}
          isExtraAction
        />,
      ]}
      menuClassName="w-250"
    >
      {({ isDropdownOpened }) => (
        <MenuItemWithDropdown
          title={translate("estimateRooms") as string}
          isDropdownOpened={isDropdownOpened}
        />
      )}
    </Dropdown>
  );
}
