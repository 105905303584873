import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React from "react";
import { isCreateEstimateRoomModalVisibleVar } from "../../graphql/cache";
import CreateEstimateRoomModal from "../createEstimateRoomModal/CreateEstimateRoomModal";
import CreateRetrospectiveRoomModal from "../createRetrospectiveRoomModal/CreateRetrospectiveRoomModal";
import Dropdown from "../dropdown/Dropdown";

export default function CreateRoomModal() {
  const translate = useTranslations();

  const openCreateEstimateRoomModal = () => {
    isCreateEstimateRoomModalVisibleVar(true);
  };

  return (
    <div className="flex flex-row items-center h-full">
      <Dropdown
        menuItems={[
          <div
            className="cursor-pointer text-sm font-medium py-3 hover:bg-main-blue hover:text-white px-3 rounded-md text-gray-900 dark:text-white"
            onClick={openCreateEstimateRoomModal}
            key={"dropdown-create-estimate-room"}
          >
            {translate("estimate")}
          </div>,
          <CreateRetrospectiveRoomModal key={"dropdown-create-retro-room"} />,
        ]}
        menuClassName="w-250"
      >
        {({ isDropdownOpened }) => (
          <div
            className={`ml-8 flex flex-row items-center cursor-pointer rounded-md border px-4 py-2 text-gray-900 ${
              isDropdownOpened ? "bg-main-blue !text-white" : ""
            } dark:text-white`}
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
            <div className="font-semibold text-xs ml-2">
              {translate("createRoom")}
            </div>
          </div>
        )}
      </Dropdown>
      <CreateEstimateRoomModal />
    </div>
  );
}
