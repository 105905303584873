import { useTranslations } from "next-intl";
import Head from "next/head";
import AppHeader from "../appHeader/AppHeader";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  pageTitle: string;
}

export default function Layout({ children, pageTitle }: LayoutProps) {
  const translate = useTranslations();
  const pageDesc = translate("ob1Desc") as string;
  return (
    <div className="w-full h-full">
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={pageDesc} />
        <meta name="og:title" content={pageTitle} />
        <meta name="twitter:card" content="summary_large_image" />
        <title>{pageTitle}</title>
      </Head>
      <AppHeader />
      <main className="flex flex-col items-center pt-20">{children}</main>
    </div>
  );
}
