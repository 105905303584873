import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface MenuItemWithDropdownProps {
  title: string;
  isDropdownOpened: boolean;
}

export default function MenuItemWithDropdown({
  title,
  isDropdownOpened,
}: MenuItemWithDropdownProps) {
  return (
    <div className={`flex flex-row items-center mx-4`}>
      <div
        className={`text-sm font-medium mr-2 ${
          isDropdownOpened ? "text-main-blue" : "text-dark-blue dark:text-white"
        }`}
      >
        {title}
      </div>
      <FontAwesomeIcon
        icon={isDropdownOpened ? faCaretUp : faCaretDown}
        size="lg"
        className="scale-80"
      />
    </div>
  );
}
