import { useTranslations } from "next-intl";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import {
  isCreateEstimateRoomModalVisibleVar,
  isGlobalLoadingVar,
} from "../../graphql/cache";
import { useIsGlobalLoading } from "../../hooks/useIsGlobalLoading";
import {
  CurrentUserDocument,
  GetJoinedRoomsDocument,
  useCreateRoomMutation,
  useIsCreateEstimateRoomModalVisibleQuery,
} from "../../resolvers-types";
import Modal from "../modal/Modal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import AppInput from "../appInput/AppInput";
import { TRACKING_EVENT } from "../../constants/common";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useApolloClient } from "@apollo/client";
import { useLogGuestUserIn } from "../../hooks/useLogGuestUserIn";
import { nanoid } from "nanoid";

interface CreateNewRoomFormValues {
  name: string;
  displayName: string;
}

const initialCreateNewRoomFormValues: CreateNewRoomFormValues = {
  name: "",
  displayName: "",
};

export default function CreateEstimateRoomModal() {
  const translate = useTranslations();
  const { setGlobalLoading } = useIsGlobalLoading(isGlobalLoadingVar);
  const [createRoom] = useCreateRoomMutation({
    refetchQueries: [{ query: GetJoinedRoomsDocument }],
    awaitRefetchQueries: true,
  });
  const { data } = useIsCreateEstimateRoomModalVisibleQuery();
  const isModalVisible = data.isCreateEstimateRoomModalVisible;
  const { currentUser } = useCurrentUser();
  const isGuest = !currentUser;
  const client = useApolloClient();
  const loginUser = useLogGuestUserIn();

  const router = useRouter();

  const createNewRoomValidationSchema = useMemo(() => {
    const additionalValidation = isGuest
      ? {
          displayName: Yup.string()
            .min(2, translate("validateDisplayNameMin") as string)
            .max(50, translate("validateDisplayNameMax") as string)
            .required(translate("validateDisplayNameRequired") as string),
        }
      : {};
    return Yup.object().shape({
      name: Yup.string()
        .min(2, translate("validateRoomNameMin") as string)
        .max(50, translate("validateRoomNameMax") as string)
        .required(translate("validateRoomNameRequired") as string),
      ...additionalValidation,
    });
  }, [translate, isGuest]);

  const closeModal = () => {
    isCreateEstimateRoomModalVisibleVar(false);
  };

  const handleSubmitForm = async (formData: CreateNewRoomFormValues) => {
    try {
      setGlobalLoading(true);
      if (isGuest && formData.displayName) {
        await loginUser({ displayName: formData.displayName, email: nanoid() });
        await client.query({ query: CurrentUserDocument });
      }
      const { data } = await createRoom({
        variables: {
          createRoomInput: {
            name: formData.name,
          },
        },
      });
      mixpanel.track(TRACKING_EVENT.createRoom, { ...formData });
      if (data.createRoom.id) {
        closeModal();
        const newRoomUrl = `/estimate/${data.createRoom.uniqId}`;
        const isInEstimateRoom = router.pathname.includes("estimate");
        if (isInEstimateRoom) {
          window.open(newRoomUrl, "_blank");
        } else {
          router.push(newRoomUrl);
        }
      }
      setGlobalLoading(false);
    } catch (error) {
      toast.error(translate("errorCreateRoom"));
      setGlobalLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialCreateNewRoomFormValues}
      validationSchema={createNewRoomValidationSchema}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, resetForm }) => {
        const closeFormModal = () => {
          resetForm();
          closeModal();
        };

        return (
          <Modal
            title={translate("createEstimateRoom") as string}
            onClickMainAction={handleSubmit}
            mainActionTitle={translate("create") as string}
            onClose={closeFormModal}
            isVisible={isModalVisible}
          >
            <Form>
              <div className="flex flex-col items-center justify-center">
                <Field
                  type="name"
                  name="name"
                  component={AppInput}
                  placeholder={translate("roomNamePlaceholder") as string}
                  label={translate("roomName")}
                />
                {isGuest && (
                  <div className="mt-5 w-full">
                    <Field
                      type="displayName"
                      name="displayName"
                      component={AppInput}
                      placeholder={
                        translate("displayNamePlaceholder") as string
                      }
                      label={translate("displayName")}
                      autofocus={false}
                    />
                  </div>
                )}
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
