import React from "react";
import Link from "next/link";

interface DropdownItemProps {
  title: string;
  onClick?: () => void;
  url?: string;
  isExtraAction?: boolean;
}

export default function DropdownItem({
  title,
  onClick,
  url,
  isExtraAction,
}: DropdownItemProps) {
  const dropdownItemClassName = `${
    isExtraAction
      ? "!font-semibold text-main-blue"
      : "text-gray-900 dark:text-white"
  } cursor-pointer text-sm font-medium py-3 hover:bg-main-blue hover:text-white px-3 rounded-md`;
  if (onClick) {
    return (
      <div className={dropdownItemClassName} onClick={onClick}>
        {title}
      </div>
    );
  }

  return (
    <Link href={url}>
      <a className={`${dropdownItemClassName} hover:no-underline`}>{title}</a>
    </Link>
  );
}
