import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { APP_INFO } from "../../constants/common";
import Modal from "../modal/Modal";

export default function ContactModal() {
  const translate = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div
        className="text-base underline cursor-pointer flex flex-row items-center"
        onClick={openModal}
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className={`text-main-blue cursor-pointer mr-4`}
          size="lg"
        />
        {translate("contactDeveloper")}
      </div>
      <Modal
        title={translate("contactDeveloper") as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={isModalVisible}
        isConfirmModal
        shouldHideFooter
        modalClassName="w-1/4"
      >
        <div className="text-sm mb-4 font-medium leading-6">
          {translate("contactDesc")}{" "}
        </div>
        <div className="flex flex-row items-center pb-3">
          <FontAwesomeIcon
            icon={faEnvelope}
            className={`text-main-blue cursor-pointer mr-3`}
            size="lg"
          />
          <a
            href={`mailto:${APP_INFO.supportEmail}`}
            className="text-main-blue text-sm underline"
          >
            {APP_INFO.supportEmail}
          </a>
        </div>
      </Modal>
    </div>
  );
}
