import { useTranslations } from "next-intl";
import React from "react";

export default function CreateRetrospectiveRoomModal() {
  const translate = useTranslations();

  return (
    <div className="flex flex-row items-center opacity-70">
      <div className="text-sm font-medium py-3 px-3 rounded-md text-gray-900 dark:text-white">
        {translate("retrospective")}
      </div>
      <div className="px-2 py-1 rounded-md bg-teal-500 text-white text-xs">
        {translate("comingSoon")}
      </div>
    </div>
  );
}
