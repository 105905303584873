import Link from "next/link";
import React from "react";
import Image from "next/image";
import { APP_INFO } from "../../constants/common";
import AppLogo from "../../public/images/app-logo.png";
import InitialLoadingSection from "./InitialLoadingSection";
import CreateRoomModal from "../createRoomModal/CreateRoomModal";
import Menu from "../menu/Menu";

export default function AppHeader() {
  return (
    <header className="w-full fixed bg-white dark:bg-dark-blue z-50 shadow-md">
      <div className="w-full px-10 h-20 no-underline flex flex-row items-center justify-between">
        <div className="flex flex-row items-center h-full">
          <Link href={"/"}>
            <a className="flex flex-row items-center hover:no-underline">
              <Image
                src={AppLogo}
                alt="me"
                width="32"
                height="32"
                className="rounded-lg"
                priority
              />
              <div className="text-main-blue dark:text-white dark:hover:text-main-blue hover:text-dark-blue font-bold ml-3">
                {APP_INFO.appName}
              </div>
            </a>
          </Link>
          <CreateRoomModal />
          <Menu />
        </div>
        <InitialLoadingSection />
      </div>
    </header>
  );
}
