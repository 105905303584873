import React, { ReactNode } from "react";

interface DropdownPropsRenderProps {
  isDropdownOpened: boolean;
}
interface DropdownProps {
  menuItems: ReactNode[];
  children: ReactNode | ((props: DropdownPropsRenderProps) => ReactNode);
  menuClassName?: string;
}

export default function Dropdown({
  menuItems,
  children,
  menuClassName,
}: DropdownProps) {
  const [isOpened, setIsOpened] = React.useState(false);

  const showDropdown = () => {
    setIsOpened(true);
  };

  const closeDropdown = () => {
    setIsOpened(false);
  };

  const childComp =
    typeof children === "function"
      ? children({ isDropdownOpened: isOpened })
      : children;

  return (
    <div
      className="w-auto h-full cursor-pointer relative"
      onMouseOver={showDropdown}
      onMouseLeave={closeDropdown}
    >
      <div className="h-full items-center flex">{childComp}</div>
      {isOpened && (
        <div
          className={`shadow-lg flex flex-col absolute z-50 pt-3 pb-4 rounded-bl-lg rounded-br-lg px-3 bg-white dark:bg-dark-blue border mt-0 min-w-200 right-0 ${menuClassName}`}
        >
          {menuItems}
        </div>
      )}
    </div>
  );
}
