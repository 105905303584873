import React, { useEffect, useRef } from "react";

export default function AppInput({
  field,
  form: { touched, errors },
  autofocus = true,
  ...props
}) {
  const inputRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    if (autofocus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  return (
    <div className="w-full">
      {props.label && <div className="text-xs mb-2">{props.label}</div>}
      <input
        className="appearance-none rounded w-full py-3 px-4 border-main-blue border-2 dark:bg-dark-blue dark:text-white text-dark-blue leading-tight focus:outline-none focus:shadow-outline text-sm"
        type="text"
        ref={inputRef}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="text-xs font-medium text-red-400 mt-2 ml-1">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
}
