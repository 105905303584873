import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import {
  LOCAL_STORAGE_THEME_KEY,
  TRACKING_EVENT,
} from "../../constants/common";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ThemeType } from "../../types/common";
import mixpanel from "mixpanel-browser";
import { useIsPremium } from "../../hooks/useIsPremium";
import { isProModalOpenedVar } from "../../graphql/cache";
import ReactTooltip from "react-tooltip";
import { useTranslations } from "next-intl";
import { ThemeContext } from "../../providers/ThemeProvider";

const THEME_TOGGLE_TOOLTIP_ID = "theme-toggle";

export default function ThemeToggle() {
  const isPremium = useIsPremium();
  const translate = useTranslations();
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (
      (localStorage[LOCAL_STORAGE_THEME_KEY] === ThemeType.Dark ||
        (!(LOCAL_STORAGE_THEME_KEY in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)) &&
      isPremium
    ) {
      enableDarkTheme();
    } else {
      disableDarkTheme();
    }
  }, []);

  const isDarkMode = theme === ThemeType.Dark;

  const enableDarkTheme = () => {
    if (!isPremium) {
      isProModalOpenedVar(true);
      return;
    }
    document.documentElement.classList.add("dark");
    setTheme(ThemeType.Dark);
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeType.Dark);
  };

  const disableDarkTheme = () => {
    document.documentElement.classList.remove("dark");
    setTheme(ThemeType.Light);
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeType.Light);
  };

  const handleToggleTheme = () => {
    if (isDarkMode) {
      disableDarkTheme();
      mixpanel.track(TRACKING_EVENT.selectTheme, { themeValue: "light" });
    } else {
      enableDarkTheme();
      mixpanel.track(TRACKING_EVENT.selectTheme, { themeValue: "dark" });
    }
  };

  const handleClickToggle = () => {
    if (!isPremium) {
      isProModalOpenedVar(true);
    } else {
      handleToggleTheme();
    }
  };

  const icon = isDarkMode ? faSun : faMoon;

  return (
    <div data-tip={translate("toggleTheme")} data-for={THEME_TOGGLE_TOOLTIP_ID}>
      <FontAwesomeIcon
        icon={icon}
        className={`text-main-blue dark:text-white cursor-pointer scale-110`}
        onClick={handleClickToggle}
      />
      <ReactTooltip id={THEME_TOGGLE_TOOLTIP_ID} place="bottom" />
    </div>
  );
}
