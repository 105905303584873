import { useApolloClient } from "@apollo/client";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../../constants/common";
import { isGlobalLoadingVar } from "../../graphql/cache";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { CurrentUserDocument, User } from "../../resolvers-types";
import { handleSignOut, identifyMixpanelProfile } from "../../utils/common";
import SupportCenterModal from "../supportCenterModal/SupportCenterModal";
import ThemeToggle from "../themeToggle/ThemeToggle";
import SignedInUserDropdown from "./SignedInUserDropdown";

export default function InitialLoadingSection() {
  const translate = useTranslations();
  const { currentUser } = useCurrentUser();
  const [isInitialLoading, setIsInitialLoading] = useState(!currentUser);
  const client = useApolloClient();

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
        if (accessToken) {
          const { data } = await client.query({ query: CurrentUserDocument });
          if (data && data?.currentUser?.id) {
            identifyMixpanelProfile(data.currentUser as User);
          } else {
            handleSignOut();
          }
        } else {
          setIsInitialLoading(false);
        }
      } catch (error) {
        handleSignOut();
      }
    };
    initializeUser();
  }, []);

  useEffect(() => {
    if (currentUser && isInitialLoading) {
      setIsInitialLoading(false);
    }
  }, [currentUser, isInitialLoading]);

  useEffect(() => {
    isGlobalLoadingVar(isInitialLoading);
  }, [isInitialLoading]);

  return (
    <div className="flex flex-row items-center h-full">
      {!isInitialLoading && (
        <>
          {currentUser ? (
            <SignedInUserDropdown currentUser={currentUser as User} />
          ) : (
            <Link href={"/login"}>
              <a className="text-sm font-medium cursor-pointer hover:no-underline flex flex-row items-center">
                <FontAwesomeIcon
                  icon={faUser}
                  className={`text-main-blue dark:text-white cursor-pointer`}
                  size="lg"
                />
                <div className="ml-3 text-main-blue dark:text-white">
                  {translate("login")}
                </div>
              </a>
            </Link>
          )}
          {currentUser && (
            <div className="ml-4 border-l-2 pl-4">
              <ThemeToggle />
            </div>
          )}
        </>
      )}
      <SupportCenterModal />
    </div>
  );
}
