import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { APP_INFO } from "../../constants/common";
import ContactModal from "../contactModal/ContactModal";
import FAQModal from "../faqModal/FAQModal";
import FAQOpenButton from "../faqModal/FAQOpenButton";
import Modal from "../modal/Modal";
import Onboarding from "./Onboarding";

export default function SupportCenterModal() {
  const translate = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ml-4 border-l-2 pl-4">
      <div
        onClick={openModal}
        className="w-5 h-5 rounded-full bg-main-blue dark:bg-white flex items-center justify-center cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faInfo}
          className={`text-white dark:text-main-blue`}
          size="xs"
        />
      </div>
      <Modal
        title={translate("supportCenter") as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={isModalVisible}
        isConfirmModal
        shouldHideFooter
      >
        <div className="w-full">
          <Onboarding />
          <FAQOpenButton />
          <ContactModal />
        </div>
      </Modal>
    </div>
  );
}
