import { useTranslations } from "next-intl";
import React from "react";
import JoinedRoomsDropdown from "./JoinedRoomsDropdown";
import SimpleMenuItem from "./SimpleMenuItem";

export default function Menu() {
  const translate = useTranslations();
  return (
    <div className="flex flex-row items-center ml-5 h-full">
      <SimpleMenuItem
        path="/articles"
        title={translate("articles").toString()}
      />
      <JoinedRoomsDropdown />
    </div>
  );
}
