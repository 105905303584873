import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React from "react";
import { isOnboardingModalOpenedVar } from "../../graphql/cache";

export default function Onboarding() {
  const translate = useTranslations();

  const openModal = () => {
    isOnboardingModalOpenedVar({
      isOpened: true,
      title: translate("onboarding") as string,
    });
  };

  return (
    <div
      className="mb-6 text-base underline cursor-pointer flex flex-row items-center"
      onClick={openModal}
    >
      <FontAwesomeIcon
        icon={faBookOpen}
        className={`text-main-blue cursor-pointer mr-4`}
        size="lg"
      />
      {translate("onboarding")}
    </div>
  );
}
