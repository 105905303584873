import { ReactiveVar } from "@apollo/client";

export function useIsGlobalLoading(isGlobalLoadingVar: ReactiveVar<boolean>) {
  const setGlobalLoading = (isLoading: boolean) => {
    isGlobalLoadingVar(isLoading);
  };

  return {
    setGlobalLoading,
  };
}
