import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React from "react";
import { isFAQOpenedVar } from "../../graphql/cache";

export default function FAQOpenButton() {
  const translate = useTranslations();

  const openModal = () => {
    isFAQOpenedVar(true);
  };

  return (
    <div
      className="mb-6 text-base underline cursor-pointer flex flex-row items-center"
      onClick={openModal}
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className={`text-main-blue cursor-pointer mr-4`}
        size="lg"
      />
      {translate("faq")}
    </div>
  );
}
