import Link from "next/link";
import React from "react";

interface SimpleMenuItemProps {
  title: string;
  path: string;
}

export default function SimpleMenuItem({ title, path }: SimpleMenuItemProps) {
  return (
    <Link href={path}>
      <a className="text-sm text-dark-blue dark:text-white font-medium hover:no-underline hover:text-main-blue dark:hover:text-main-blue py-1 rounded-md mx-4">
        {title}
      </a>
    </Link>
  );
}
